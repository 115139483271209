import { FC, ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

import { StyledCardHeader } from './styles';

type CommonSelectDialogProps = Omit<DialogProps, 'title' | 'classes' | 'children' | 'content'> & {
  subtitle?: string;
  content?: ReactNode;
  actions?: ReactNode;
  headerAction?: ReactNode;
  onClose: () => void;
  fullWidth?: boolean;
  CustomActions?: ReactNode;
  Content?: ReactNode;
};

type SelectDialogHeaderProps = { title: string | ReactNode; Header?: never } | { Header: ReactNode; title?: never };

type SelectDialogProps = CommonSelectDialogProps & SelectDialogHeaderProps;

export const SelectDialog: FC<SelectDialogProps> = (props) => {
  const {
    title,
    subtitle,
    content,
    actions,
    headerAction,
    onClose,
    fullWidth = true,
    Header: PropsHeader,
    Content: PropsContent,
    CustomActions = null,
    ...dialogProps
  } = props;

  const handleClose = () => onClose();

  return (
    <Dialog
      aria-labelledby='simple-dialog-title'
      fullWidth={fullWidth}
      maxWidth='md'
      onClose={handleClose}
      {...dialogProps}
    >
      {PropsHeader ?? <StyledCardHeader title={title} subheader={subtitle} action={headerAction} />}
      {PropsContent ?? <DialogContent sx={{ padding: '10px 20px' }}>{content}</DialogContent>}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {actions && <DialogActions sx={{ margin: '20px' }}>{actions}</DialogActions>}
      {CustomActions}
    </Dialog>
  );
};
