import { CredsProvider } from '@playq/services-common-adhawk';

import AdColony from './adhawk/adcolony.png';
import Adjust from './adhawk/adjust.png';
import AppleSearch from './adhawk/apple_search_ads.png';
import Applike from './adhawk/applike.png';
import Applovin from './adhawk/applovin.png';
import ChartBoost from './adhawk/chartboost.png';
import CrossInstall from './adhawk/crossinstall.png';
import DigitalTurbine from './adhawk/digitalturbine.png';
import Facebook from './adhawk/facebook.png';
import Google from './adhawk/google.png';
import IronSource from './adhawk/ironsource.png';
import Kochava from './adhawk/Kochava-logo.png';
import Lifestreet from './adhawk/lifestreet.png';
import Moloco from './adhawk/moloco.png';
import TapJoy from './adhawk/tapjoy.png';
import Tune from './adhawk/tune.png';
import Unity from './adhawk/unityads.png';
import Vungle from './adhawk/vungle.png';

export const AdHawkIcons: { [key: string]: string } = {
  [CredsProvider.ADCOLONY]: AdColony as string,
  [CredsProvider.ADJUST]: Adjust as string,
  [CredsProvider.ADJUSTMMP]: Adjust as string,
  [CredsProvider.ADJUSTSKAN]: Adjust as string,
  [CredsProvider.ADJUSTCOHORT]: Adjust as string,
  [CredsProvider.APPLESEARCH]: AppleSearch as string,
  [CredsProvider.APPLESKAN]: AppleSearch as string,
  [CredsProvider.APPLIKE]: Applike as string,
  [CredsProvider.APPLOVIN]: Applovin as string,
  [CredsProvider.APPLOVINMAX]: Applovin as string,
  [CredsProvider.APPLOVINPROFIT]: Applovin as string,
  [CredsProvider.APPLOVINUSERREVENUE]: Applovin as string,
  [CredsProvider.CHARTBOOST]: ChartBoost as string,
  [CredsProvider.CROSSINSTALL]: CrossInstall as string,
  [CredsProvider.DIGITALTURBINE]: DigitalTurbine as string,
  [CredsProvider.FACEBOOK]: Facebook as string,
  [CredsProvider.FACEBOOKBUSINESS]: Facebook as string,
  [CredsProvider.GOOGLEADS]: Google as string,
  [CredsProvider.IRONSOURCE]: IronSource as string,
  [CredsProvider.IRONSOURCEBUSINESS]: IronSource as string,
  [CredsProvider.IRONSOURCEADREVENUE]: IronSource as string,
  [CredsProvider.IRONSOURCECOST]: IronSource as string,
  [CredsProvider.KOCHAVA]: Kochava as string,
  [CredsProvider.KOCHAVAAGGREGATED]: Kochava as string,
  [CredsProvider.LIFESTREET]: Lifestreet as string,
  [CredsProvider.MOLOCO]: Moloco as string,
  [CredsProvider.MOLOCOANALYTICS]: Moloco as string,
  [CredsProvider.TAPJOY]: TapJoy as string,
  [CredsProvider.TAPJOYV2]: TapJoy as string,
  [CredsProvider.TUNE]: Tune as string,
  [CredsProvider.UNITY]: Unity as string,
  [CredsProvider.VUNGLE]: Vungle as string,
} as const;
