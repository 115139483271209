import _ from 'lodash';

import {
  Filter,
  FilterExpression,
  NumberFilter,
  NumberFilterSerialized,
  SortDirection,
  TextFilter,
  TextFilterSerialized,
  TimeFilter,
  TimeFilterSerialized,
} from '@playq/octopus-common';

import { IBaseSortI } from '/shared/Table';

/**
 * T is an entity type, SC - sort type (IBaseSortI<SC>)
 */

type QueryingFilter<T> = (entities: T[], filterBy: Record<string, Filter>) => T[];
type QueryingSort<T, SC extends string> = (entities: T[], sortBy: IBaseSortI<SC>) => T[];
type QueryingEntities<T, SC extends string> = (
  entities: T[],
  filterBy: Record<string, Filter>,
  sortBy: IBaseSortI<SC>
) => T[];

export interface IQuerying<T, SC extends string> {
  filterEntities: QueryingFilter<T>;
  sortEntities: QueryingSort<T, SC>;
  queryEntities: QueryingEntities<T, SC>;
}

export interface IQueryingDefinitions<T> {
  filterField?: string;
  sortField?: string;
  getValue: (e: T) => unknown;
}

/**
 * V is value type
 */
export type FilterFunction<V> = (value: V, filter: Filter) => boolean;

export function textFilter(value: string, filter: TextFilter | TextFilterSerialized): boolean {
  return value.toLowerCase().includes(filter.text.toLowerCase());
}

const EXPRESSIONS_WITH_TIME_SELECT = [FilterExpression.InRange, FilterExpression.OutOfRange];

export function timeFilter(value: Date, filter: TimeFilter | TimeFilterSerialized): boolean {
  const { expression, left, right } = filter;
  const leftComparableValue: Date = typeof left === 'string' ? new Date(left) : left;

  const isFilterDateWithoutTime =
    (value.getHours() === 0 && value.getMinutes() === 0) ||
    !EXPRESSIONS_WITH_TIME_SELECT.includes(expression as FilterExpression);

  if (isFilterDateWithoutTime) {
    value.setHours(0, 0, 0, 0);
    leftComparableValue.setHours(0, 0, 0, 0);
  }

  switch (expression) {
    case FilterExpression.Equal:
      return value.getTime() === leftComparableValue.getTime();
    case FilterExpression.NotEqual:
      return value.getTime() !== leftComparableValue.getTime();
    case FilterExpression.Less:
      return value < leftComparableValue;
    case FilterExpression.LessOrEqual:
      return value <= leftComparableValue;
    case FilterExpression.Greater:
      return value > leftComparableValue;
    case FilterExpression.GreaterOrEqual:
      return value >= leftComparableValue;
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!right) {
    return false;
  }

  const rightComparableValue = typeof right === 'string' ? new Date(right) : right;

  if (isFilterDateWithoutTime) {
    rightComparableValue.setHours(23, 59, 59, 999);
  }

  switch (expression) {
    case FilterExpression.InRange:
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return !!rightComparableValue && value > leftComparableValue && value < rightComparableValue;
    case FilterExpression.OutOfRange:
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return !!rightComparableValue && (value <= leftComparableValue || value >= rightComparableValue);
    default:
      return false;
  }
}

export function numberFilter(value: number, filter: NumberFilter | NumberFilterSerialized): boolean {
  const { expression, left, right } = filter;

  switch (expression) {
    case FilterExpression.Equal:
      return value === left;
    case FilterExpression.NotEqual:
      return value !== left;
    case FilterExpression.Less:
      return value < left;
    case FilterExpression.LessOrEqual:
      return value <= left;
    case FilterExpression.Greater:
      return value > left;
    case FilterExpression.GreaterOrEqual:
      return value >= left;
  }

  if (right === undefined) {
    return false;
  }

  switch (expression) {
    case FilterExpression.InRange:
      return value > left && value < right;
    case FilterExpression.OutOfRange:
      return value <= left || value >= right;
    default:
      return false;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getFilterFunction(filter: Filter): FilterFunction<any> {
  switch (filter.getClassName()) {
    case TimeFilter.ClassName:
      return timeFilter as FilterFunction<unknown>;
    case TextFilter.ClassName:
    default:
      return textFilter as FilterFunction<unknown>;
  }
}

export const createQuerying = <T, SC extends string = string>(
  definitions: IQueryingDefinitions<T>[]
): IQuerying<T, SC> => {
  const filterEntities: QueryingFilter<T> = (entities: T[], filterBy: Record<string, Filter>) => {
    let result = entities;

    if (!_.isEmpty(filterBy)) {
      Object.keys(filterBy).forEach((key: string) => {
        const keyDef = definitions.find((def: IQueryingDefinitions<T>) => def.filterField === key);
        if (!keyDef) {
          console.error(`There is no definition with ${key} filterField`);
          return;
        }

        const filterFunction = getFilterFunction(filterBy[key]);

        result = result.filter((entity: T) => {
          const value = keyDef.getValue(entity);
          return filterFunction(value, filterBy[key]);
        });
      });
    }

    return result;
  };

  const sortEntities: QueryingSort<T, SC> = (entities: T[], sortBy: IBaseSortI<SC>) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!sortBy || _.isEmpty(sortBy) || sortBy.ord === SortDirection.None) {
      return entities;
    }

    const sortDef = definitions.find((def: IQueryingDefinitions<T>) => def.sortField === sortBy.field);

    if (!sortDef) {
      console.error(`There is no definition with ${sortBy.field} sortField`);
      return entities;
    }

    const ascEntities = _.sortBy(entities, [sortDef.getValue]);
    return sortBy.ord === SortDirection.Ascending ? ascEntities : ascEntities.reverse();
  };

  const queryEntities: QueryingEntities<T, SC> = (
    entities: T[],
    filterBy: Record<string, Filter>,
    sortBy: IBaseSortI<SC>
  ) => {
    const filteredEntities = filterEntities(entities, filterBy);
    return sortEntities(filteredEntities, sortBy);
  };

  return { filterEntities, sortEntities, queryEntities };
};
