import { Environment, SpaceID } from '@playq/octopus-common';
import { PromotionEnvironmentSource, PromotionSpaceSource } from '@playq/octopus2-apps-utility';

type ReturnType<T extends SpaceID | Environment | undefined> = T extends SpaceID
  ? PromotionSpaceSource
  : T extends Environment
    ? PromotionEnvironmentSource
    : undefined;

export const createPromotionSource = <T extends SpaceID | Environment | undefined>(id: T): ReturnType<T> => {
  if (id === undefined) {
    return undefined as ReturnType<T>;
  }
  if (id instanceof SpaceID) {
    const result = new PromotionSpaceSource();
    result.spaceID = id;
    return result as ReturnType<T>;
  }
  if (Object.values(Environment).includes(id)) {
    return new PromotionEnvironmentSource({ env: id }) as ReturnType<T>;
  }
  return undefined as ReturnType<T>;
};
