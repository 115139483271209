import { CardHeader, styled } from '@mui/material';

export const StyledCardHeader = styled(CardHeader)`
  & .MuiCardHeader-root {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: ${({ theme }) => theme.spacing(4)};
    justify-content: space-between;
  }

  & .MuiCardHeader-action {
    margin: 0;
  }
`;
