/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Formula,
  Node,
  NodeGroup,
  NodeHelpers as SystemNodeHelpers,
  NodeVarBool,
  NodeVarDouble,
  NodeVarFormula,
  NodeVarInt,
  NodeVarString,
  OperandLiteralBoolean,
  Recurrence,
} from '@playq/octopus-common';
import { Formatter } from '@playq/irt';
import { GameEvent } from '@playq/octopus2-liveops';

import { IGameEventInternalGeneral } from '/component/LiveOps/GameEvents/GameEvent/GameEventTypes';
import { resetModel } from '/helpers/model';
import { initialDates } from '/shared/DateRangePicker/helpers';

import { TreeHelpers } from './tree';
import { NodeHelpers } from './node';

const { initialStartDate, initialEndDate } = initialDates();

export class GameEventHelpers {
  static readonly hiddenNodes = [
    'general.useFixedDuration',
    'general.useDeviceTime',
    'general.targetSegment',
    'general.name',
    'general.minVersion',
    'general.exposure',
    'general.exclusive',
  ];

  static readGameEvent(gameEvent?: GameEvent): {
    contentTree: GameEvent;
    recurrence?: Recurrence;
    general: IGameEventInternalGeneral;
  } {
    let nContentTree: GameEvent;

    if (gameEvent) {
      const content = TreeHelpers.findByPath('content', gameEvent) as NodeGroup;
      if (content !== undefined) {
        const clonedContent = SystemNodeHelpers.deserialize(
          JSON.parse(JSON.stringify(SystemNodeHelpers.serialize(content)))
        ) as NodeGroup;
        const generalNode = TreeHelpers.findByPath('general', gameEvent) as NodeGroup;

        nContentTree = new GameEvent(gameEvent.serialize());
        nContentTree.nodes = [...clonedContent.children];

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const general = generalNode ? this.writeGeneral(generalNode) : this.emptyGeneralSchema();
        return {
          contentTree: nContentTree,
          recurrence: gameEvent?.recurrence,
          general,
        };
      }
    }

    nContentTree = this.emptyGameEvent();
    return {
      contentTree: nContentTree,
      recurrence: undefined,
      general: this.emptyGeneralSchema(),
    };
  }

  static writeGameEvent(
    general: IGameEventInternalGeneral,
    contentTree: GameEvent,
    recurrence: Recurrence | undefined,
    gameEvent?: GameEvent
  ): GameEvent {
    const copyGeneral = { ...general };
    let generalNode: NodeGroup;
    if (gameEvent) {
      const content = TreeHelpers.findByPath('content', gameEvent) as NodeGroup;
      content.children = contentTree.nodes;

      generalNode = TreeHelpers.findByPath('general', gameEvent) as NodeGroup;
      NodeHelpers.updateNodeByValueSchema(generalNode, copyGeneral as any);
      gameEvent.mutations = contentTree.mutations;

      TreeHelpers.hideNodes(gameEvent, GameEventHelpers.hiddenNodes);
      return gameEvent;
    }

    const nGameEvent = this.emptyGameEvent();

    nGameEvent.recurrence = recurrence;
    nGameEvent.mutations = contentTree.mutations;
    const contentNode = NodeHelpers.emptyNodeGroup('content');
    contentNode.children = contentTree.nodes;

    generalNode = NodeHelpers.emptyNodeGroup('general');
    NodeHelpers.updateNodeByValueSchema(generalNode, general as any);

    nGameEvent.nodes.push(contentNode, generalNode);
    TreeHelpers.hideNodes(nGameEvent, GameEventHelpers.hiddenNodes);

    return nGameEvent;
  }

  static emptyGeneralSchema(): IGameEventInternalGeneral {
    const initialFormula = new Formula();
    initialFormula.raw = 'true';
    initialFormula.expression = new OperandLiteralBoolean({ value: true, origin: undefined });

    return {
      name: new NodeVarString({ val: '' }),
      minVersion: new NodeVarString({ val: '' }),
      duration: new NodeVarInt({ val: 0 }),
      startDate: new NodeVarString({ val: Formatter.writeZoneDateTime(initialStartDate) }),
      endDate: new NodeVarString({ val: Formatter.writeZoneDateTime(initialEndDate) }),
      exclusive: new NodeVarBool({ val: false }),
      useDeviceTime: new NodeVarBool({ val: true }),
      useFixedDuration: new NodeVarBool({ val: false }),
      exposure: new NodeVarDouble({ val: 1.0 }),
      targetSegment: new NodeVarFormula({ val: initialFormula.serialize() }),
      leaderboardSegmentation: {},

      leaderboard: undefined,
      leaderboardSegmentationOverride: undefined,
    };
  }

  static writeGeneral(generalGroup: NodeGroup): IGameEventInternalGeneral {
    const general = this.emptyGeneralSchema();
    if (!generalGroup.children.length) {
      return general;
    }

    generalGroup.children.forEach((n: Node) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      general[n.name as unknown as string] = NodeHelpers.getNodeVar(n);
    });

    return general;
  }

  static emptyGameEvent(): GameEvent {
    const gameEvent = new GameEvent();
    resetModel(gameEvent);
    gameEvent.version = 1;
    return gameEvent;
  }
}
